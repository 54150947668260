import { Product } from '@Types/product/Product';
import './importVviinn';

function VviinnCrossSellingTastic({ data }) {
  const { product }: { product: Product } = data?.data?.dataSource;
  const { vviinnToken, title }: { vviinnToken: string; title: string } = data;
  return (
    <vviinn-vpr-widget campaign-type="VCS" token={vviinnToken} product-id={product.productId} block-title={title} />
  );
}

export default VviinnCrossSellingTastic;
